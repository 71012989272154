import React from 'react'
import styled from 'styled-components'
import {
  Box,
  Button,
  Title3,
  Large,
  colors,
} from 'js-components'
import { navigate } from 'gatsby';
import { Section, WrapperBackground } from '../../components/Wrapper'
import { VideoFrame } from '../../components/VideoFrame'

import QAndTruly from './customerLogos/qAndTruly.svg'

const AboutPage = () => (
  <div>
    <WrapperBackground
      color={colors.trulyDark}
      background='businessScene1'
    >
      <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width={[1, 2/3]}>
          <HeroLogo src={QAndTruly} />
          <Large as="p" color={colors.white} mb="1.5rem" center>
          Learn how Managed by Q consolidated their sales tech stack and reduced IT costs while delivering more value to their sales teams.
          </Large>
          <Button size="extraLarge" color={colors.accentFog} round value="Download the Case Study (PDF)" type="outline" onClick={() => navigate('/customers/q')}></Button>
        </Box>
      </Section>
    </WrapperBackground>

    <WrapperBackground
      color={colors.trulyDark}
      background='businessScene1'
      gradientType='light'
    >
      <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width={[1, 1/2]} pl={['0pt', '32pt']}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem">
          ALL-IN-ONE
          </Title3>
          <Large as="p" color={colors.trulyDark} mb="1.5rem">
            Managed by Q had previously used four different solutions to support the phone needs of their sales team. Truly was able to replace all systems and deliver increased value with unparalleled customer support.
          </Large>
          <VideoFrame />
        </Box>
        <Box width={[0, 1/2]}>
        </Box>
      </Section>
    </WrapperBackground>

    <WrapperBackground
      color={colors.trulyDark}
      background='businessScene1'
    >
      <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width={[0, 1/2]}>
        </Box>
        <Box width={[1, 1/2]} pl={['0pt', '32pt']}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem">
          IT JUST WORKS
          </Title3>
          <Large as="p" color={colors.white} mb="1.5rem">
            The IT team at Managed by Q always felt left high & dry by vendor support. Truly's success practice and support team resolves issues before they happen and advise the Q team when issues do arise to ensure their IT infrastructure becomes better for the future.
          </Large>
        </Box>
      </Section>
    </WrapperBackground>

  </div>
)

export default AboutPage

const HeroLogo = styled.img`
  width:50%;
  margin-bottom: 32pt;
  margin: 0 auto;
`
